import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/hi_galicia_coming_soon.png" className="coming-soon" alt='coming soon' />
        <img src="/hi_galicia_coming_soon_mobile.png" alt="coming soon" className="coming-soon-mobile" />
        {/* <div className="video-container">
          <video autoPlay muted loop>
            <source src="/hi_galicia_coming_soon.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
      </header>
    </div>
  );
}

export default App;
